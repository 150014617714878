<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
//import DatePicker from "vue2-datepicker";
//import NumberInputSpinner from "vue-number-input-spinner";
import Swal from "sweetalert2";
import axios from 'axios';
import VueGauge from 'vue-gauge';
import VueSlideBar from "vue-slide-bar";
import DatePicker from "vue2-datepicker";
import vueSignature from "vue-signature";
import Multiselect from "vue-multiselect";
import Customer from "../shop/customerNew";
import Labor from "../shop/laborNew";
import error403 from '../utility/error-403';

import {
  required,
  email,
  minLength,
  //sameAs,
  maxLength,
  //minValue,
  //maxValue,
  //numeric,
  //url,
  alphaNum,
  requiredIf
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Work order registation",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, VueGauge, VueSlideBar, DatePicker, vueSignature, Multiselect, Customer, Labor, error403 /*DatePicker, NumberInputSpinner*/ },
  props:{
    id:[Number,null],
    rights: Array
  },
  data() {
    return {
      title: "Work order registration",
      items: [
        {
          text: "Shop",
          href: "/"
        },
        {
          text: "Work orders",
          href: "/Shop/workorders"
        },
        {
          text: "Work order",
          active: true
        }
      ],

      //Array for entities
      user:                       {},
      customers:                  [],
      years:                      [],
      makes:                      [],
      models:                     [],
      engines:                    [],
      transmissions:              [],
      labors:                     [],
      mechanics:                  [],
      selected:                   [],
      filter:                     null,
      filterOn:                   [],
      selectedCustomerCar:        null,
      customerCars:               [],
      permission:                [1,2,12],


      laborsFields:['labor', 'quantity', 'suggested_price', 'shop_price', 'total'],
      //Canvas options
      option: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255,255,255)",
        minWidth: 0.5,
        maxWidth: 1.0,
      },
      option2: {
        penColor: "rgb(255, 0, 0)",
        backgroundColor: "rgb(255,255,255)",
        minWidth: 1,
        maxWidth: 1.2,
      },
      disabled: false,
      dataUrl: "https://autoshop.blueseal.me/dist/mvw/uploads/carMap.png",

      //Helper vars
      today:              new Date(),
      currentDay:         "",
      currentMonth:       "",
      currentYear:        "",
      id_customerType:    "",
      selectedCustomer:   "",
      changePass:         false,
      num_gasoline:       0,
      keyGaugeRender:     0,
      num_advancePayment: parseFloat("0.00").toFixed(2),

      //Data usage
      usoDeDatos1:        false,
      usoDeDatos2:        false,
      usoDePublicidad1:   false,
      usoDePublicidad2:   false,

      submitted:          false,
      validToken:         false,
      validAccess:        true,

      form: {
        id_assignedMechanic:      "",
        id_assignedEmployee:      "",
        //WorkOrder
        num_customerPhoneNumber:  "",
        de_customerEmail:         "",
        nm_customerName:          "",
        num_year:                 "",
        nm_make:                  "",
        nm_model:                 "",
        num_engineDisplacement :  "",
        de_transmission:          "",
        num_licensePlate:         "",
        num_vin:                  "",
        num_milesKM:              "",
        de_color:                 "",

        //WorkOrder Revisions
        de_externals:             [],
        de_internals:             [],
        de_accesories:            [],
        de_inventory:             [],
        de_mechanicComponents:    [],
        num_gasoline:             0,
        url_carMap:               "",
        de_observations:          "",
        url_signatureAssesor:     "",
        url_signatureReception:   "",

        //WorkOrderCost
        de_spareParts:            [],
        num_externalService:      parseFloat("0.00").toFixed(2),
        num_workForce:            parseFloat("0.00").toFixed(2),
        num_spareParts:           parseFloat("0.00").toFixed(2),
        num_subTotal:             parseFloat("0.00").toFixed(2),
        num_taxes:                parseFloat("0.00").toFixed(2),
        num_totalPrice:           parseFloat("0.00").toFixed(2),
        num_advancePayment:       parseFloat("0.00").toFixed(2),
        num_remains:              parseFloat("0.00").toFixed(2),
        num_exchange:             parseFloat("0.00").toFixed(2),
        num_guaranteeDays:        0,
        num_guaranteeMilesKM:     0,
        sn_guarantee:             false,

        //WorkOrder Diagnostic
        de_diagnostic:            "",
        dt_expeditionDate:        "",
        nm_diagnosticAuthor:      "",
        de_notes:                 "",
        sn_acceptSpareParts:      false,
        sn_provideSpareParts:     false,
        sn_useOfData:             false,
        sn_useOfPublicity:        false,
        url_signatureService:     "",
        url_signatureCustomer:    "",
        de_status:                "",
        de_nextRevisionDateKM:    "",
      },
      gaugeOptions:{
        needleValue:              0,
        arcDelimiters:            [10, 20, 30, 40, 50, 60, 70, 80, 90],
        arcColors:                ['#b91d47','#ffc40d','#ffc40d','#ffc40d','#ffc40d','#00a300','#00a300','#00a300','#00a300','#00a300'],
        arcLabels:                ['10', '20', '30', '40', '50', '60', '70', '80', '90', '100'],
        arcPadding:               0,
        arcOverEffect:            false,
        chartWidth:               '225',
        hasNeedle:                true,
      },


      externals:[
        {name:"Unidad Luces",             value:false},
        {name:"1/4 Luces",                value:false},
        {name:"Antena",                   value:false},
        {name:"Espejo Lateral",           value:false},
        {name:"Cristales",                value:false},
        {name:"Emblema",                  value:false},
        {name:"Llantas (4)",              value:false},
        {name:"Tapón válvula llanta",     value:false},
        {name:"Tapón del rin",            value:false},
        {name:"Molduras Completas",       value:false},
        {name:"Micas Traseras",           value:false},
        {name:"Limpiadores (Wipes)",      value:false},
        {name:"Tapón de Gasolina",        value:false},
        
      ],
      externals2:[
        {name:"Tapón Ruebas",             value:false},
        {name:"Molduras Completas",       value:false},
        {name:"Tapón Gasolina",           value:false},
        {name:"Carrocería sin Golpes",    value:false},
        {name:"Bocinas Claxón",           value:false},
        {name:"Limpiadores (Wipes)",      value:false},
      ],
      internals: [
        {name:"Instrumentos de tablero",  value:false},
        {name:"Clima",                    value:false},
        {name:"Audio/Multimedia",         value:false},
        {name:"Bocinas",                  value:false},
        {name:"Encendendor",              value:false},
        {name:"Espejo Retrovisor Tablero",value:false},
      ],
      inventory:[
        {name:"Cargador Celular",         value:false},
        {name:"Cinturones",               value:false},
        {name:"Botones de Puertas",       value:false},
        {name:"Manijas Interiores",       value:false},
        {name:"Tapetes",                  value:false},
        {name:"Vestiduras",               value:false},
        {name:"Libro/Documentos",         value:false},
      ],
      accesories:[
        {name:"Gato",                     value:false},
        {name:"Maneral de Gato",          value:false},
        {name:"Llave o Cruceta",          value:false},
        {name:"Estuche Herramienta",      value:false},
        {name:"Triangulo emergencia",     value:false},
        {name:"Llanta de Refacción",      value:false},
        {name:"Extinguidor",              value:false},
        {name:"Cables pasa corriente",    value:false},
        {name:"Tapadera Cajuela",         value:false},
        {name:"Cubierta motor",           value:false},
        {name:"Acumulador/Batería",       value:false},
      ],
      mechanicComponents:[
        {name:"Varilla de Aceite Motor",  value:false},
        {name:"Varilla Aceite Transm",    value:false},
        {name:"Tapón Aceite",             value:false},
        {name:"Tapón Radiador",           value:false},
      ],

      colors: ["NEGRO", "AZUL", "CAFE", "GRIS", "VERDE", "NARANJA", "ROSA", "VIOLETA", "ROJO", "BLANCO", "TINTO", "AMARILLO", "DORADO", "PLATEADO"],
    };
  },
  validations: {
    form: {
      nm_customerName:          { required },
      de_customerEmail:         { required, email },
      num_customerPhoneNumber:  { required },
      num_year:                 { required },
      nm_make:                  { required },
      nm_model:                 { required },
      num_engineDisplacement :  { required },
      de_transmission:          { required },
      num_licensePlate:         { required },
      num_vin:                  { required, alphaNum, minLength: minLength(17), maxLength: maxLength(17) },
      num_milesKM:              { required },
      de_color:                 { required },


      num_workForce:            { required },
      num_externalService:      { required },
      num_subTotal:             { required },
      num_taxes:                { required },
      num_totalPrice:           { required },

      de_diagnostic:            { required: requiredIf(function(){ return Array(1,2,4,12).includes(this.user.user.id_role); }) },
      dt_expeditionDate:        { required: requiredIf(function(){ return Array(1,2,4,12).includes(this.user.user.id_role); }) },
      nm_diagnosticAuthor:      { required: requiredIf(function(){ return Array(1,2,4,12).includes(this.user.user.id_role); }) },

      sn_useOfData:             { required: requiredIf(function(){ return Array(1,2,4,12).includes(this.user.user.id_role); }) },
      sn_useOfPublicity:        { required: requiredIf(function(){ return Array(1,2,4,12).includes(this.user.user.id_role); }) },
    }
  },
  computed:{
    //Calculates subTotal
      num_subTotal: function(){
        return (parseFloat(this.form.num_spareParts) + parseFloat(this.form.num_workForce) +  parseFloat(this.form.num_externalService)).toFixed(2);
      },
      //Calculates taxes8%
      num_taxes: function(){
        return parseFloat( (this.num_subTotal / 100) * 8).toFixed(2);
      },
      //Calculates total price to pay
      num_totalPrice: function(){
        return (parseFloat( this.num_subTotal ) + parseFloat( this.num_taxes )).toFixed(2);
      },
      //Calculates pending billing
      num_remains: function(){
        const rest = parseFloat( this.num_totalPrice - this.num_advancePayment);
        return rest.toFixed(2);
      },
  },
  watch:{
    //Watchers for Data usage
    usoDeDatos1: function(){
      if(this.usoDeDatos1){
        this.usoDeDatos2 = false;
      }
    },
    usoDeDatos2: function(){
      if(this.usoDeDatos2){
        this.usoDeDatos1 = false;
      }
    },

    //Watchers for marketing usage
    usoDePublicidad1: function(){
      if(this.usoDePublicidad1){
        this.usoDePublicidad2 = false;
      }
    },
    usoDePublicidad2: function(){
      if(this.usoDePublicidad2){
        this.usoDePublicidad1 = false;
      }
    },
    validToken(newValue){
      if( newValue == false ){
        this.$bvModal.show('loginModal');
      }
    },
    "rights": function(newvalue, oldvalue){
      if(oldvalue !== newvalue){
        if(!newvalue.includes(this.user.user.id_role)){
          this.validAccess = false;
        }else{
          this.validAccess = true;
        }
      }
    }
  },
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }
  },
  beforeMount(){
    //Filling up years array from 1960 until 2020
    for(var i=1985; i<=parseInt(this.today.getFullYear()); i++){
      this.years.push(i);
    }
  },
  afterMount(){
  },
  mounted() {
    this.colors.sort();
    this.getMechanics();
    this.getCustomers();
    this.getLaborsForOrders();

    this.currentDay   = this.today.getDate();
    this.currentMonth = this.today.getMonth()+1;
    this.currentYear  = this.today.getFullYear();

    if( this.$route.params.id ){
      this.getWorkorderByID(this.$route.params.id);
    }
  },
  methods: {
    roleIncluded(){
      return this.permission.includes(this.user.user.id_role);
    },

    async getSites(){
      Swal.fire({
        title: "Loading Sites",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      let app = this;

      axios
      .get(
        window.serverPath+'dist/mvw/controllers/sites.php',
        {
          params: {
            request: 'getSites',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.sites = response.data;
          Swal.close();
          }
        }else{
          Swal.fire("Ooops!", "It was not possible to load Companies! Please load this module again.", "error");
        }
      });
    },

    snGetCustomers(){
      if(this.id_customerType == 0){
        this.form.nm_customerName         = "";
        this.form.de_customerEmail        = "";
        this.form.num_customerPhoneNumber = "";
      }

      if( this.id_customerType == 1){
        this.selectedCustomer = "";
        this.getCustomers();
      }
    },

    setCustomerData(){
      this.form.de_customerEmail        = this.selectedCustomer.de_customerEmail;
      this.form.num_customerPhoneNumber = this.selectedCustomer.num_customerPhoneNumber;
      this.form.nm_customerName         = this.selectedCustomer.nm_customerName;
    },

    getCustomers(hide=false){
      if(!hide){
        Swal.fire({
          title: "Loading customers",
          showConfirmButton:false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });
      }
      let app = this;

      axios
      .get(
        window.serverPath+'dist/mvw/controllers/customers.php',
        {
          params: {
            request: 'getCustomersForMultipleSelect',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.customers = response.data;
          Swal.close();
          }
        }else{
          Swal.fire("Ooops!", "It was not possible to load Companies! Please load this module again.", "error");
        }
      });
    },

    getMechanics(){
      let app = this;
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/users.php',
        {
          params: {
            request: 'getMechanicsByCompanyId',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.mechanics = response.data;
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load Labor! Please load this module again.", "error");
        }
      });
    },

    getLaborsForOrders(){
      let app = this;
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/labors.php',
        {
          params: {
            request: 'getLaborsForOrders',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.labors = response.data;
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load Labor! Please load this module again.", "error");
        }
      });
    },

    getLabor(){
      Swal.fire({
        title: "Loading labor data.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      let app = this;
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/labors.php',
        {
          params: {
            request: 'getLaborsByID',
            id:this.$route.params.id,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.labor = response.data;

            this.form.id_site         = app.labor.id_site;
            this.form.de_labor        = app.labor.de_labor;
            this.form.num_laborPrice  = app.labor.num_laborPrice;

            Swal.close();
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load Labor! Please load this module again.", "error");
        }
      });
    },

    getBrandsFromYear(){
      let app = this;

      if( !this.$route.params.id ){
        this.form.nm_make                 = "";
        this.form.nm_model                = "";
        this.form.num_engineDisplacement  = "";
        this.form.de_transmission         = "";
      }

      Swal.fire({
        title: "Loading makes data.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });

      axios
      .get(
        window.serverPath+'dist/mvw/controllers/cars.php',
        {
          params: {
            request: 'getMakesForOrders',
            num_year: app.form.num_year,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.makes = response.data;
            Swal.close();
            if( app.$route.params.id ){
              app.getModelsForOrders();
            }
          }
        }else{
          Swal.fire("Ooops!", "It was not possible to load Companies! Please load this module again.", "error");
        }
      });
    },

    getModelsForOrders(){
      let app = this;

      if( !this.$route.params.id ){
        this.form.nm_model                = "";
        this.form.num_engineDisplacement  = "";
        this.form.de_transmission         = "";
      }

      Swal.fire({
        title: "Loading models data.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });

      axios
      .get(
        window.serverPath+'dist/mvw/controllers/cars.php',
        {
          params: {
            request: 'getModelsForOrders',
            num_year: app.form.num_year,
            nm_make: app.form.nm_make,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.models = response.data;
            Swal.close();
            if( app.$route.params.id ){
              app.getEnginesForOrders();
            }
          }
        }else{
          Swal.fire("Ooops!", "It was not possible to load Companies! Please load this module again.", "error");
        }
      });
    },

    getEnginesForOrders(){
      let app = this;

      if( !this.$route.params.id ){
        this.form.num_engineDisplacement  = "";
        this.form.de_transmission         = "";
      }

      Swal.fire({
        title: "Loading models data.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });

      axios
      .get(
        window.serverPath+'dist/mvw/controllers/cars.php',
        {
          params: {
            request: 'getEnginesForOrders',
            num_year: app.form.num_year,
            nm_make: app.form.nm_make,
            nm_model: app.form.nm_model,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.engines = response.data;
            Swal.close();

            if( app.$route.params.id ){
              app.getTransmissionsForOrders();
            }
          }
        }else{
          Swal.fire("Ooops!", "It was not possible to load Companies! Please load this module again.", "error");
        }
      });
    },

    getTransmissionsForOrders(){
      let app = this;

      if( !this.$route.params.id ){
        this.form.de_transmission         = "";
      }

      Swal.fire({
        title: "Loading transmissions data.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });

      axios
      .get(
        window.serverPath+'dist/mvw/controllers/cars.php',
        {
          params: {
            request: 'getTransmissionsForOrders',
            num_year: app.form.num_year,
            nm_make: app.form.nm_make,
            nm_model: app.form.nm_model,
            num_engineDisplacement:app.form.num_engineDisplacement,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.transmissions = response.data;
            Swal.close();
          }
        }else{
          Swal.fire("Ooops!", "It was not possible to load Companies! Please load this module again.", "error");
        }
      });
    },

    async getWorkorderByID(idWorkorder){
      let app = this;
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/workorders.php',
        {
          params: {
            request: 'getWorkorderByID',
            id: idWorkorder,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            let data = response.data;
            app.form.id_assignedEmployee      = data.wo.id_assignedEmployee;
            app.form.id_assignedMechanic      = data.wo.id_assignedMechanic;
            //Workorder
            app.form.nm_customerName          = data.wo.nm_customerName;
            app.form.de_customerEmail         = data.wo.de_customerEmail;
            app.form.num_customerPhoneNumber  = data.wo.num_customerPhoneNumber;
            app.form.num_year                 = data.wo.num_year;
            app.form.nm_make                  = data.wo.nm_make;
            app.form.nm_model                 = data.wo.nm_model;
            app.form.num_engineDisplacement   = data.wo.num_engineDisplacement;
            app.form.de_transmission          = data.wo.de_transmission;
            app.form.num_licensePlate         = data.wo.num_licensePlate;
            app.form.num_vin                  = data.wo.num_vin;
            app.form.num_milesKM              = data.wo.num_milesKM;
            app.form.de_color                 = data.wo.de_color;

            //Revision
            app.externals                     = data.rv.de_externals;
            app.internals                     = data.rv.de_internals;
            app.accesories                    = data.rv.de_accesories;
            app.inventory                     = data.rv.de_inventory;
            app.mechanicComponents            = data.rv.de_mechanicComponents;
            app.num_gasoline                  = data.rv.num_gasoline;
            app.num_advancePayment            = parseFloat(data.ct.num_advancePayment).toFixed(2);
            app.form.de_observations          = data.rv.de_observations;

            app.form.de_status                =data.wo.de_status;

            this.updateGauge();

            //Cost
            data.ct.de_spareParts.forEach(function(item){
              return item['_rowVariant'] = "success";
            });
            app.selected                      = data.ct.de_spareParts;
            app.form.num_externalService      = parseFloat(data.ct.num_externalService).toFixed(2);
            app.form.num_workForce            = parseFloat(data.ct.num_workForce).toFixed(2);
            app.form.num_spareParts           = parseFloat(data.ct.num_spareParts).toFixed(2);
            app.form.num_subTotal             = data.ct.num_subTotal;
            app.form.num_taxes                = data.ct.num_taxes;
            app.form.num_totalPrice           = data.ct.num_totalPrice;
            app.form.num_advancePayment       = parseFloat(data.ct.num_advancePayment).toFixed(2);
            app.form.num_exchange             = parseFloat(data.ct.num_exchange).toFixed(2);
            app.form.sn_guarantee             = (data.ct.sn_guarantee == 1) ? true : false;

            //Diagnostic
            app.form.de_diagnostic            = data.dx.de_diagnostic;
            app.form.dt_expeditionDate        = ( data.dx.dt_expeditionDate != "0000-00-00") ? data.dx.dt_expeditionDate : "";
            app.form.nm_diagnosticAuthor      = data.dx.nm_diagnosticAuthor;
            app.form.de_notes                 = data.dx.de_notes;
            app.form.sn_acceptSpareParts      = (data.dx.sn_acceptSpareParts == 1) ? true : false;
            app.form.sn_provideSpareParts     = (data.dx.sn_provideSpareParts == 1) ? true : false;
            app.form.sn_useOfData             = data.dx.sn_useOfData;
            app.form.sn_useOfPublicity        = data.dx.sn_useOfPublicity;
            app.form.de_nextRevisionDateKM    = data.dx.de_nextRevisionDateKM;
            
            //Artificio para pantalla solamente
            if( data.dx.sn_useOfData == 1 ){
              app.usoDeDatos1 = true;
            }else{
              app.usoDeDatos2 = true;
            }

            if( data.dx.sn_useOfPublicity == 1 ){
              app.usoDePublicidad1 = true;
            }else{
              app.usoDePublicidad2 = true;
            }

            //Searching the correct customer to be selected from the list of customers.
            this.customers.forEach(function(customer){
              if(customer.nm_customerName == data.wo.nm_customerName){
                app.selectedCustomer = customer;
              }
            });

            //Marking on green the spare parts or services of the workorder
            this.selected.forEach(function(item){
              for(let i=0; i<app.labors.length; i++){
                if(app.labors[i].id_labor == item.id_labor){
                  app.labors[i].shop_price = item.shop_price;
                  app.labors[i].quantity = item.quantity;
                  app.labors[i]['_rowVariant'] = 'success';
                  app.labors[i]['selected'] = true;
                  app.$refs['selectableTable'].selectRow(i);
                }
              }
            });


            //Loading car map and signatures
            app.$refs['signature'].fromDataURL(data.dx.url_signatureService);
            app.$refs['signature2'].fromDataURL(data.dx.url_signatureCustomer);
            app.$refs['signature3'].fromDataURL(data.rv.url_carMap);

            if( data.rv.url_signatureAssesor != '' ){ app.$refs['signature4'].fromDataURL(data.rv.url_signatureAssesor); }
            if( data.rv.url_signatureReception != '' ){ app.$refs['signature5'].fromDataURL(data.rv.url_signatureReception); }

            app.getBrandsFromYear();

            var wo_date       = data.wo.dt_creation.split("-");
            this.currentDay   = wo_date[2];
            this.currentMonth = wo_date[1];
            this.currentYear  = wo_date[0];
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load the workorder! Please load this module again.", "error");
        }
      });
    },    

    // eslint-disable-next-line no-unused-vars
    checkHeadInvoice() {
      let app = this;

      this.submitted                    = true;
      // stop here if form is invalid

      this.form.de_externals            = this.externals;
      this.form.de_internals            = this.internals;
      this.form.de_accesories           = this.accesories;
      this.form.de_inventory            = this.inventory;
      this.form.de_mechanicComponents   = this.mechanicComponents;
      this.form.num_gasoline            = this.num_gasoline;
      this.form.url_carMap              = this.save('signature3');

      if(this.$route.params.id || this.user.user.id_role == 3){
        this.form.url_signatureAssesor    = this.save('signature4');
        this.form.url_signatureReception  = this.save('signature5');
      }

      this.form.sn_useOfData          = ( this.usoDeDatos1 ) ? true : false;
      this.form.sn_useOfPublicity     = ( this.usoDePublicidad1 ) ? true : false;

      this.form.de_spareParts         = this.selected;
      this.form.num_taxes             = this.num_taxes;
      this.form.num_subTotal          = this.num_subTotal;
      this.form.num_totalPrice        = (parseFloat( this.num_subTotal ) + parseFloat( this.num_taxes )).toFixed(2);
      this.form.num_advancePayment    = parseFloat(this.num_advancePayment).toFixed(2);
      this.form.num_remains           = this.num_remains;

      this.form.url_signatureService  = this.save('signature');
      this.form.url_signatureCustomer = this.save('signature2');
      
      
      this.$v.$touch();
      /*
      if( !this.$v.form.$error ){
        console.log('sin errores');
      }else{
        console.log(this.$v.form.$error)
      }
      */
      
      
      if( !this.$v.form.$error ){
        if( this.$route.params.id ){
          this.form.id_workorder = this.$route.params.id;
        }

        Swal.fire({
          title: "Saving workorder.",
          showConfirmButton:false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });

        axios.post(window.serverPath+'dist/mvw/controllers/workorders.php', {
          request: 'saveWorkOrder',
          data: this.form,
          token: this.user.token,
        })
        .then(function (response) {
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            //let action = "";
            if( !isNaN(response.data) ){
              //action = "saved";

              Swal.fire(
                "Good job!", "Workorder #"+response.data+" has been generated.!",
                'success',
              ).then((result) => {
                if (result.isConfirmed) {
                  app.$router.push({ name: 'workorders'});
                }
              });
            }

            if( response.data == "UPDATED"){
              Swal.fire(
                "Good job!", "Workorder #"+app.$route.params.id+" has been updated.!",
                'success',
              ).then((result) => {
                if (result.isConfirmed) {
                  app.$router.push({ name: 'workorders'});
                }
              });
            }
          }
          //Swal.fire("Good job!", "Your labor is correct "+action+"!", "success");
          //app.$router.push({ name: 'labors' });
        })
        .catch(function (error) {
          Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
        });
      }else{
        console.log(this.$v.form);
        Swal.fire("Ooops!", "Please check all required fields are completed!", "error");
      }
    },

    updateStatus(){
      let app = this;

      app.$bvToast.toast(
        "Workorder (#"+this.$route.params.id+") status.",
        {
          title: 'Updating',
          variant: 'info',
          solid: true
        }
      );

      axios.post(window.serverPath+'dist/mvw/controllers/workorders.php', {
          request: 'updateWorkorderStatus',
          data: {
            id_workorder: app.$route.params.id,
            de_status:    app.form.de_status
          },
          token: this.user.token,
        })
        .then(function (response) {
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            //let action = "";
            if( !isNaN(response.data) ){
              app.$bvToast.toast(
                "Workorder (#"+app.$route.params.id+") status has been updated to "+app.form.de_status+".",
                {
                  title: 'Workorder status updated',
                  variant: 'success',
                  solid: true
                }
              );
              app.$router.push({ name: 'workorders' });
            }else{
              app.$bvToast.toast(
                "There was a problem when trying to update the status of the workorder. Please try again.",
                {
                  title: 'Workorder status updating error.',
                  variant: 'danger',
                  solid: true
                }
              );
            }
          }
          //Swal.fire("Good job!", "Your labor is correct "+action+"!", "success");
          //app.$router.push({ name: 'labors' });
        })
        .catch(function (error) {
          Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
        });
    },

    updateGauge(){
      this.gaugeOptions.needleValue = this.num_gasoline;
      this.keyGaugeRender += 1;
    },

    onRowSelected(items) {
      let app = this;

      this.form.num_spareParts = 0.00;
      this.selected = items;

      this.labors.forEach(function(labor){
        items.forEach(function(item){
          if( labor.id_labor == item.id_labor ){
            return labor['selected'] = true;
          }
        });
      });

      this.selected.forEach(function(item){
        app.form.num_spareParts += parseFloat(item.suggested_price);
        app.form.num_spareParts.toFixed(2);
      });
    },

    onFiltered(filteredItems) {
      //let app   = this;
      let rows  = filteredItems.length;

      //Marking on green the spare parts or services of the workorder
      for( let i=0; i<rows; i++){
        if(filteredItems[i].selected){
          //app.$refs['selectableTable'].selectRow(i);
          this.tbodyRowClass(filteredItems[i]);
        }
      }
    },

    rowClicked(item) {
      //let app = this;

      if( item._rowVariant ){
        Swal.fire(
          "Wait!",
          "You cannot remove already saved services/spare parts of an invoice, only adding new.",
          "warning"
        );
      }else{
        if( item.selected ){
          let app       = this;
          let aux       = [];
          var index     = 0;
          var manforce  = 0;
          var external  = 0;
          var parts     = 0;

          manforce, external, parts;

          //Reset the sum
          //this.form.num_externalService = 0.00;
          //this.form.num_workForce       = 0.00;
          //this.form.num_spareParts      = 0.00;


          if( item.shop_price > 0 ){
            this.form.num_spareParts          = (item.id_laborType == 1) ? parseFloat(this.form.num_spareParts) - ( parseFloat(item.shop_price) * parseFloat(item.quantity) ) : 0;
            this.form.num_workForce           = (item.id_laborType == 2) ? parseFloat(this.form.num_workForce) - ( parseFloat(item.shop_price) * parseFloat(item.quantity) ) : 0;
            this.form.num_externalService     = (item.id_laborType == 3) ? parseFloat(this.form.num_externalService) - ( parseFloat(item.shop_price) * parseFloat(item.quantity) ) : 0;
          }else{
            this.form.num_spareParts          = (item.id_laborType == 1) ? parseFloat(this.form.num_spareParts) - ( parseFloat(item.shop_price) * parseFloat(item.suggested_price) ) : 0;
            this.form.num_workForce           = (item.id_laborType == 2) ? parseFloat(this.form.num_workForce) - ( parseFloat(item.shop_price) * parseFloat(item.suggested_price) ) : 0;
            this.form.num_externalService     = (item.id_laborType == 3) ? parseFloat(this.form.num_externalService) - ( parseFloat(item.shop_price) * parseFloat(item.suggested_price) ) : 0;
          }
          
          /*
          manforce      = item.workforce;
          external      = parseFloat(item.external_service);
          parts         = ( item.shop_price > 0 ) ? (parseFloat(item.quantity) * parseFloat(item.shop_price)) : (parseFloat(item.quantity) * parseFloat(item.suggested_price));

          this.form.num_workForce           = (item.id_laborType == 1) ? parseFloat(this.form.num_workForce) - manforce;
          this.form.num_externalService     = (item.id_laborType == 1) ? parseFloat(this.form.num_externalService) - external;
          this.form.num_spareParts          = (item.id_laborType == 1) ? parseFloat(this.form.num_spareParts) - parseFloat(parts);
          */

          this.labors.forEach(function(labor){
            if(item.id_labor == labor.id_labor){
              app.labors[index].quantity   = 0;
              app.labors[index].shop_price = 0;
            }
            index ++;
          });

          //Remove "selected" property from the item
          delete item.selected;
          //remove the item from the selected array
          this.removeFromSelected(item);
          //Remove the item from the labors array to avoid duplicates
          //this.removeFromLabors(item);
          //Add the item back to the labors
          //this.labors.push(item);

          //Filtering the ones are selected
          this.labors = this.labors.filter(({selected}) => !selected);
          //Sorting the items by idLabor
          this.labors = this.labors.sort(({id_labor:a}, {id_labor:b}) => a-b);

          //Merging selected and labors arrays.
          aux = [...this.selected, ...this.labors];
          //Clearing labors
          this.labors = [];
          //Setting labors to all items, highlighted and sorted
          this.labors = aux;
        }else{
          let aux   = [];
          let aux2  = [];

          item.selected = !item.selected;
          this.selected.push(item);
          this.removeFromLabors(item);
          aux2.push(item);
          
          aux = [...aux2, ...this.labors];
          
          this.labors = [];
          this.labors = aux;
        }
      }
      
      /*
      if(!item._rowVariant){
        if(item.selected){      
          let index = 0;

          this.$set(item, 'selected', false);
          delete item.selected;
          
          this.selected.forEach(function(part){
            if(part.id_labor == item.id_labor){
              if( parseFloat(part.shop_price) > 0 ){
                app.form.num_spareParts -= parseFloat(part.shop_price);
                app.form.num_spareParts.toFixed(2);
              }else{
                app.form.num_spareParts -= parseFloat(part.suggested_price);
                app.form.num_spareParts.toFixed(2);
              }

              app.selected.splice(index, 1);
            }
            index ++;
          });
        }else{
          this.$set(item, 'selected', true);

          this.form.num_spareParts = 0.00;
          this.selected.push(item);

          this.selected.forEach(function(part){
            if( parseFloat(part.shop_price) > 0 ){
              app.form.num_spareParts += parseFloat(part.shop_price);
              app.form.num_spareParts.toFixed(2);
            }else{
              app.form.num_spareParts += parseFloat(part.suggested_price);
              app.form.num_spareParts.toFixed(2);
            }
          });
        }
      }else{
        Swal.fire(
          "Wait!",
          "You cannot remove already saved services/spare parts of an invoice, only adding new.",
          "warning"
        );
      }
      */
    },

    removeFromLabors(item){
      let app = this;
      var index = 0;
      this.labors.forEach( function(part){
        if( part.id_labor == item.id_labor){
          app.labors.splice(index, 1);
        }
        index++;
      });
    },

    removeFromSelected(item){
      let app = this;
      var index = 0;
      this.selected.forEach( function(part){
        if( part.id_labor == item.id_labor){
          app.selected.splice(index, 1);
        }
        index++;
      });
    },

    setShopPrice(e, index, data){
      let app       = this;
      //let sum       = 0;
      let parts     = 0;
      let manforce  = 0;
      let external  = 0;
      var value     = parseFloat(e.target.value);

      app;
      e;
      index;
      data;
      parts;
      manforce;
      external;
      value;

      if( value < 0 ){
        this.labors[index][data.field.key] = 0;
        Swal.fire("Ooops!", "You cannot set a price lower than $0.00.", "error");
        return false;
      }else{
        if( data.field.key == "quantity"){
          data.item.quantity = e.target.value;
        }

        if( data.field.key == "shop_price"){
          data.item.shop_price = e.target.value;
        }

        if( !this.labors[index].selected ){
          this.labors[index].selected = true;
          this.$refs['selectableTable'].selectRow(index);
          this.selected.push(this.labors[index]);
        }

        //Reset the sum
        this.form.num_externalService = 0.00;
        this.form.num_workForce       = 0.00;
        this.form.num_spareParts      = 0.00;

        this.selected.forEach( function(labor){
          if( labor.shop_price > 0 ){
            parts     += (labor.id_laborType == 1 ) ? (parseFloat(labor.quantity) * parseFloat(labor.shop_price)) : 0;
            manforce  += (labor.id_laborType == 2 ) ? (parseFloat(labor.quantity) * parseFloat(labor.shop_price)) : 0;
            external  += (labor.id_laborType == 3 ) ? (parseFloat(labor.quantity) * parseFloat(labor.shop_price)) : 0;
          }else{
            parts     += (labor.id_laborType == 1 ) ? (parseFloat(labor.quantity) * parseFloat(labor.suggested_price)) : 0;
            manforce  += (labor.id_laborType == 2 ) ? (parseFloat(labor.quantity) * parseFloat(labor.suggested_price)) : 0;
            external  += (labor.id_laborType == 3 ) ? (parseFloat(labor.quantity) * parseFloat(labor.suggested_price)) : 0;
          }
        });

        this.form.num_externalService   = external;
        this.form.num_workForce         = manforce;
        this.form.num_spareParts        = parseFloat(parts).toFixed(2);
        /*
        if(e.target.value < 0 ){
          this.labors[index].shop_price = "0.00";
          Swal.fire("Ooops!", "You cannot set a price lower than $0.00.", "error");
          return false;
        }else{
          this.form.num_spareParts      = 0.00;
          this.labors[index].shop_price = parseFloat(e.target.value).toFixed(2);

          if( !this.labors[index].selected){
            this.labors[index].selected = true;
            this.$refs['selectableTable'].selectRow(index);
            this.selected.push(this.labors[index]);
          }

          this.selected.forEach(function(part){
            if( parseFloat(part.shop_price) > 0 ){
              app.form.num_spareParts   = parseFloat(app.form.num_spareParts) + parseFloat(part.shop_price);
            }else{
              app.form.num_spareParts   = parseFloat(app.form.num_spareParts) + parseFloat(part.suggested_price);
            }
          });
        }
        */
      }
    },

    calculateSum(item, index){
      item;
      console.log(this.labors[index]);
    },

    tbodyRowClass(item) {
      /* Style the row as needed */
      if(item.selected){
        //return ["b-table-row-selected", "table-primary", "cursor-pointer"]

        switch(item.id_laborType){
          default: return ["table-success"];
          case 1: return ["table-success"];
          case 2: return ["table-info"];
          case 3: return ["table-danger"]; 
        }
      }
    },

    /**
     * This section is for the signature
     * **/
    save(signature) {
      var _this = this;
      var png = _this.$refs[signature].save();
      //var jpeg = _this.$refs[signature].save("image/jpeg");
      //var svg = _this.$refs[signature].save("image/svg+xml");
      //console.log(png);
      //console.log(jpeg);
      //console.log(svg);

      return png;
    },
    clear(signature) {
      var _this = this;
      _this.$refs[signature].clear();

      if(_this.dataUrl != "" && signature == "signature3"){
        _this.$refs[signature].fromDataURL(_this.dataUrl);
      }
    },
    undo(signature) {
      var _this = this;
      _this.$refs[signature].undo();
    },
    addWaterMark() {
      var _this = this;
      _this.$refs.signature.addWaterMark({
        text: "mark text", // watermark text, > default ''
        font: "20px Arial", // mark font, > default '20px sans-serif'
        style: "all", // fillText and strokeText,  'all'/'stroke'/'fill', > default 'fill
        fillStyle: "red", // fillcolor, > default '#333'
        strokeStyle: "blue", // strokecolor, > default '#333'
        x: 100, // fill positionX, > default 20
        y: 200, // fill positionY, > default 20
        sx: 100, // stroke positionX, > default 40
        sy: 200, // stroke positionY, > default 40
      });
    },
    fromDataURL(url) {
      var _this = this;
      _this.$refs.signature.fromDataURL(url);
    },
    handleDisabled() {
      var _this = this;
      _this.disabled = !_this.disabled;
    },

    /**
     * Section for customer modal (new customer).
     * **/
    closeModal(idModal){
      this.getCustomers(true);
      this.getLaborsForOrders();
      this.$bvModal.hide(idModal);
    },
    setCustomerFields(customer){
      this.selectedCustomer             = customer;
      this.form.nm_customerName         = customer.nm_customerName;
      this.form.de_customerEmail        = customer.de_customerEmail;
      this.form.num_customerPhoneNumber = customer.num_customerPhoneNumber;
      this.getCarsByCustomerEmail();
    },

    setAssignedEmployee(){
      let app = this;
      this.mechanics.forEach(function(employee){
        if(employee.id_user == app.form.id_assignedMechanic){
          app.form.id_assignedEmployee = employee.id_employee;
        }
      });
    },

    getCarsByCustomerEmail(){
      let app = this;

      axios
      .get(
        window.serverPath+'dist/mvw/controllers/cars.php',
        {
          params: {
            request: 'getCarsByCustomerEmail',
            deEmail: app.form.de_customerEmail,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.customerCars = response.data;

            if(app.customerCars.length > 0 ){
              app.$bvModal.show("modal-customerCars");
            }else{
              app.$bvToast.toast(
                "There were no cars found for this customer.",
                {
                  title: "0 cars found.",
                  variant: "warning",
                  solid: true
                }
              );
            }
            
            Swal.close();
          }
        }else{
          Swal.fire("Ooops!", "It was not possible to load Companies! Please load this module again.", "error");
        }
      });
    },

    setCustomerCar(){
      this.form.num_year                = this.selectedCustomerCar.num_year;
      this.getBrandsFromYear();
      
      this.form.nm_make                 = this.selectedCustomerCar.nm_make;
      this.getModelsForOrders();
      
      this.form.nm_model                = this.selectedCustomerCar.nm_model;
      this.getEnginesForOrders();
      
      this.form.num_engineDisplacement  = this.selectedCustomerCar.num_engineDisplacement;
      this.getTransmissionsForOrders();
      
      this.form.de_transmission   = this.selectedCustomerCar.de_transmission;
      this.form.num_licensePlate  = this.selectedCustomerCar.num_licensePlate;
      this.form.num_vin           = this.selectedCustomerCar.num_vin;
      this.form.num_milesKM       = this.selectedCustomerCar.num_milesKM;
      this.form.de_color          = this.selectedCustomerCar.de_color;

      this.$bvModal.hide("modal-customerCars");
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />


    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div v-if="validAccess" class="card-body">
            <form class="needs-validation">
              <div class="row">
                <div class="col-sm-2">
                  <img src="@/assets/images/logo.png" alt width="120" />
                </div>

                <div class="col-sm-2">
                  <div v-if="$route.params.id" class="form-group">
                    <label for="id_customerType">Status</label>
                    <select
                      class="form-control"
                      name="de_status"
                      id="de_status"
                      v-model="form.de_status"
                      @change="updateStatus"
                    >
                      <option value="">SELECT STATUS</option>
                      <option value="NEW">NEW</option>
                      <option value="IN PROGRESS">IN PROGRESS</option>
                      <option value="ON HOLD">ON HOLD</option>
                      <option value="COMPLETED">COMPLETED</option>
                    </select>
                    <div v-if="submitted && $v.form.num_year.$error" class="invalid-feedback">
                      <span v-if="!$v.form.num_year.required">Car engine year field is required.</span>
                    </div>
                  </div>

                  <div v-else class="form-group">
                    <label for="id_customerType">New customer?</label>
                    <button type="button" class="btn btn-sm btn-success" v-b-modal.modal-customer><i class="fas fas fa-plus"></i> Add a customer</button>
                    <b-modal no-close-on-backdrop
                      id="modal-customer"
                      title="New customer"
                      title-class="font-18"
                      size="lg"
                      hide-footer
                    >
                      <Customer @success="closeModal('modal-customer')"></Customer>
                    </b-modal>
                  </div>

                  <div v-if="customerCars.length > 0" class="form-group">
                    <label></label>
                    <button type="button" class="btn btn-sm btn-dark" v-b-modal.modal-customerCars><i class="fas fas fa-car"></i> Open custome cars</button>
                    <b-modal no-close-on-backdrop
                      id="modal-customerCars"
                      title="Customer Cars"
                      title-class="font-18"
                      size="lg"
                      hide-footer
                    >
                      <b-form-group label="This customer owns the following cars:" v-slot="{ ariaDescribedby }">
                        <b-form-radio
                          v-for="(car, index) in customerCars"
                          :key="index"
                          v-model="selectedCustomerCar"
                          :aria-describedby="ariaDescribedby"
                          name="some-radios"
                          :value="car"
                          @change="setCustomerCar"
                        >
                          {{car.nm_make}} {{car.nm_model}} color {{car.de_color}} modelo {{car.num_year}}, placas {{car.num_licensePlate}}, VIN {{car.num_vin}}
                        </b-form-radio>
                      </b-form-group>
                    </b-modal>
                  </div>
                </div>

                <div class="col-sm-2">
                  <div class="form-group">
                    <label for="id_assignedMechanic">Assigned Mechanic</label>
                    <select
                      class="form-control"
                      name="id_assignedMechanic"
                      id="id_assignedMechanic"
                      v-model="form.id_assignedMechanic"
                      @change="setAssignedEmployee"
                    >
                      <option value="">SELECT MECHANIC</option>
                      <option v-for="(mechanic, index) in mechanics" :key="index" :value="mechanic.id_user">{{mechanic.nm_user}}</option>
                    </select>
                  </div>
                </div>

                <div class="col-sm-4"></div>

                <!-- DATE PRINT -->
                <div class="col-sm-2">
                  <div class="row">
                    <div class="col-sm-4 pr-0"><b>Day</b></div>
                    <div class="col-sm-4 pr-0"><b>Month</b></div>
                    <div class="col-sm-4 pr-0"><b>Year</b></div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4 pr-0">{{currentDay}}</div>
                    <div class="col-sm-4 pr-0">{{currentMonth}}</div>
                    <div class="col-sm-4 pr-0">{{currentYear}}</div>
                  </div>
                </div>
                <!-- DATE PRINT -->
              </div>
              
              <div class="row mt-3">
                <!-- CUSTOMER DATA NAME, EMAIL, PHONE NUMBER -->
                <div class="col-sm-4">

                  <div class="form-group">
                    <label for="id_states">Customer</label>
                    <multiselect
                      :class="{ 'is-invalid': submitted && $v.form.nm_customerName.$error }"
                      id="id_customer"
                      v-model="selectedCustomer"
                      :options="customers"
                      :multiple="false"
                      :close-on-select="true"
                      :clear-on-select="false"
                      :preserve-search="true"
                      placeholder="SELECT YOUR CUSTOMERS"
                      label="label"
                      track-by="label"
                      :preselect-first="false"
                      :searchable="true"
                      :preserveSearch="false"
                      @select="setCustomerFields"
                    ></multiselect>
                    <div v-if="submitted && $v.form.nm_customerName.$error" class="invalid-feedback">
                      <span v-if="!$v.form.nm_customerName.required">Customer name field is required.</span>
                    </div>
                  </div>

                  <!--
                  <div class="form-group" v-if="id_customerType == 0">
                    <label for="nm_customerName">Customer name</label>
                    <input
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.nm_customerName.$error }"
                      name="nm_customerName"
                      id="nm_customerName"
                      v-model="form.nm_customerName"
                      value=""
                    >
                    <div v-if="submitted && $v.form.nm_customerName.$error" class="invalid-feedback">
                      <span v-if="!$v.form.nm_customerName.required">Customer name is required.</span>
                    </div>
                  </div>

                  <div class="form-group" v-if="id_customerType == 1">
                    <label for="nm_customerName">Customer name</label>
                    <select
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.nm_customerName.$error }"
                      name="nm_customerName"
                      id="nm_customerName"
                      v-model="selectedCustomer"
                      @change="setCustomerData"
                    >
                      <option value="">SELECT CUSTOMER</option>
                      <option v-for="(customer, index) in customers" :key="index" :value="customer">{{customer.nm_customerName}}</option>
                    </select>
                    <div v-if="submitted && $v.form.nm_customerName.$error" class="invalid-feedback">
                      <span v-if="!$v.form.nm_customerName.required">Customer name is required.</span>
                    </div>
                  </div>
                  -->
                </div>

                <div class="col-sm-4">
                  <div class="form-group">
                    <label for="de_customerEmail">Customer email</label>
                    <input
                      type="email"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.de_customerEmail.$error }"
                      name="de_customerEmail"
                      id="de_customerEmail"
                      v-model="form.de_customerEmail"
                      value=""
                    >
                    <div v-if="submitted && $v.form.de_customerEmail.$error" class="invalid-feedback">
                      <span v-if="!$v.form.de_customerEmail.required">Customer email is required.</span>
                      <span v-if="!$v.form.de_customerEmail.email">Customer email is required.</span>
                      <!--
                      <span v-if="!$v.form.de_customerEmail.minLength">This value length is invalid. It should be between 2 and 200 characters long.</span>
                      <span v-if="!$v.form.de_customerEmail.maxLength">This value length is invalid. It should be between 2 and 200 characters long.</span>
                      -->
                    </div>
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="form-group">
                    <label for="num_customerPhoneNumber">Phone number</label>
                    <input
                      v-mask="'+(##) ###-###-####'"
                      type="text"
                      class="form-control"
                      name="num_customerPhoneNumber"
                      id="num_customerPhoneNumber"
                      v-model="form.num_customerPhoneNumber"
                      :class="{ 'is-invalid': submitted && $v.form.num_customerPhoneNumber.$error }"
                    />
                    <div v-if="submitted && $v.form.num_customerPhoneNumber.$error" class="invalid-feedback">
                      <span v-if="!$v.form.num_customerPhoneNumber.required">Phone number is required.</span>
                    </div>
                    <!--<span class="text-muted">e.g "(xx) xxxx-xxxx"</span>-->
                  </div>
                </div>
                <!-- CUSTOMER DATA NAME, EMAIL, PHONE NUMBER -->
              </div>

              <div class="row">
                <!-- CAR YEAR, MAKE, MODEL, ENGINE AND TRANSMISSION -->
                <div class="col-sm-2">
                  <div class="form-group">
                    <label for="num_year">Car engine year</label>
                    <select
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.num_year.$error }"
                      name="num_year"
                      id="num_year"
                      v-model="form.num_year"
                      @change="getBrandsFromYear"
                    >
                      <option value="">SELECT YEAR</option>
                      <option v-for="(carYear, index) in years" :key="index" :value="carYear">{{carYear}}</option>
                    </select>
                    <div v-if="submitted && $v.form.num_year.$error" class="invalid-feedback">
                      <span v-if="!$v.form.num_year.required">Car engine year field is required.</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-2">
                  <div class="form-group">
                    <label for="nm_make">Make</label>
                    <select
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.nm_make.$error }"
                      name="nm_make"
                      id="nm_make"
                      v-model="form.nm_make"
                      @change="getModelsForOrders"
                    >
                      <option value="">SELECT MAKE</option>
                      <option v-for="(make, index) in makes" :key="index" :value="make.nm_make">{{make.nm_make}}</option>
                    </select>
                    <div v-if="submitted && $v.form.nm_make.$error" class="invalid-feedback">
                      <span v-if="!$v.form.nm_make.required">Car make field is required.</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="form-group">
                    <label for="nm_model">Model</label>
                    <select
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.nm_model.$error }"
                      name="nm_model"
                      id="nm_model"
                      v-model="form.nm_model"
                      @change="getEnginesForOrders"
                    >
                      <option value="">SELECT MODEL</option>
                      <option v-for="(model, index) in models" :key="index" :value="model.nm_model">{{model.nm_model}}</option>
                    </select>
                    <div v-if="submitted && $v.form.nm_model.$error" class="invalid-feedback">
                      <span v-if="!$v.form.nm_model.required">Car engine year field is required.</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-2">
                  <div class="form-group">
                    <label for="num_engineDisplacement">Engine</label>
                    <select
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.num_engineDisplacement.$error }"
                      name="num_engineDisplacement"
                      id="num_engineDisplacement"
                      v-model="form.num_engineDisplacement"
                      @change="getTransmissionsForOrders"
                    >
                      <option value="">SELECT ENGINE</option>
                      <option v-for="(engine, index) in engines" :key="index" :value="engine.num_engineDisplacement">{{engine.num_engineDisplacement}}</option>
                    </select>
                    <div v-if="submitted && $v.form.num_engineDisplacement.$error" class="invalid-feedback">
                      <span v-if="!$v.form.num_engineDisplacement.required">Car engine year field is required.</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-2">
                  <div class="form-group">
                    <label for="de_transmission">Transmission</label>
                    <select
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.de_transmission.$error }"
                      name="de_transmission"
                      id="de_transmission"
                      v-model="form.de_transmission"
                    >
                      <option value="">SELECT TRANSMISSION</option>
                      <option v-for="(transmission, index) in transmissions" :key="index" :value="transmission.de_transmission">{{transmission.de_transmission}}</option>
                    </select>
                    <div v-if="submitted && $v.form.de_transmission.$error" class="invalid-feedback">
                      <span v-if="!$v.form.de_transmission.required">Car engine year field is required.</span>
                    </div>
                  </div>
                </div>
                <!-- CAR YEAR, MAKE, MODEL, ENGINE AND TRANSMISSION -->
              </div>

              <!-- BADGE, SERIE (VIN), KM/MILES -->
              <div class="row">
                <div class="col-sm-3">
                  <div class="form-group">
                    <label for="num_licensePlate">License Plate</label>
                    <input
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.num_licensePlate.$error }"
                      name="num_licensePlate"
                      id="num_licensePlate"
                      v-model="form.num_licensePlate"
                    />
                    <div v-if="submitted && $v.form.num_licensePlate.$error" class="invalid-feedback">
                      <span v-if="!$v.form.num_licensePlate.required">License plate number is required.</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-3">
                  <div class="form-group">
                    <label for="num_vin">Vehicle Identification Number</label>
                    <input
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.num_vin.$error }"
                      name="num_vin"
                      id="num_vin"
                      v-model="form.num_vin"
                    />
                    <div v-if="submitted && $v.form.num_vin.$error" class="invalid-feedback">
                      <span v-if="!$v.form.num_vin.required">Vehicle Identification Number is required.</span>
                      <span v-if="!$v.form.num_vin.minLength">This value length is invalid. It should 17 characters long.</span>
                      <span v-if="!$v.form.num_vin.maxLength">This value length is invalid. It should 17 characters long.</span>
                      <span v-if="!$v.form.num_vin.alphaNum">This field support only alpha numeric characters.</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-3">
                  <div class="form-group">
                    <label for="num_milesKM">Miles or KM</label>
                    <input
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.num_milesKM.$error }"
                      name="num_milesKM"
                      id="num_milesKM"
                      v-model="form.num_milesKM"
                    />
                    <div v-if="submitted && $v.form.num_milesKM.$error" class="invalid-feedback">
                      <span v-if="!$v.form.num_milesKM.required">Miles or KM is required.</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-3">
                  <div class="form-group">
                    <label for="de_color">Vehicle color</label>
                    <select
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.de_color.$error }"
                      name="de_color"
                      id="de_color"
                      v-model="form.de_color"
                    >
                      <option value="">SELECT COLOR</option>
                      <option v-for="(color, index) in colors" :key="index" :value="color">{{color}}</option>
                    </select>
                    <div v-if="submitted && $v.form.de_color.$error" class="invalid-feedback">
                      <span v-if="!$v.form.de_color.required">Vehicle color field is required.</span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- BADGE, SERIE (VIN), KM/MILES -->

              <!--
              <div class="row">
                <div class="col-lg-12 mt-5 pt-5">
                  <div class="form-group text-center">
                    <button type="button" class="btn btn-success" @click="checkHeadInvoice">SAVE LABOR</button>
                    <router-link to="/shop/labors" class="ml-3">
                      <button class="btn btn-danger" type="reset">CANCEL </button>
                    </router-link>
                  </div>
                </div>
              </div>
              -->


              <div class="row mt-5">
                <!-- EXTERNAL, INTERNAL, ACCESORIES, INVENTORY PILLS -->
                <div class="col-sm-2">
                  <div class="col-sm-12">
                    <h5 class="card-title">Externos</h5>
                    <div class="form-group-sm" v-for="(external, index) in externals" :key="index">
                      <b-form-checkbox v-model="externals[index].value" switch class="mb-1">
                        <label>{{external.name}}</label>
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
                
                <!--
                <div class="col-sm-2">
                  <h5 class="card-title">&nbsp;</h5>
                  <div class="form-group-sm" v-for="(external, index) in externals2" :key="index">
                    <b-form-checkbox v-model="externals2[index].value" switch class="mb-1">
                      <label>{{external.name}}</label>
                    </b-form-checkbox>
                  </div>
                </div>
                -->

                <div class="col-sm-2">
                  <div class="col-sm-12">
                    <h5 class="card-title">Internos</h5>
                    <div class="form-group-sm" v-for="(internal, index) in internals" :key="index">
                      <b-form-checkbox v-model="internals[index].value" switch class="mb-1">
                        <label>{{internal.name}}</label>
                      </b-form-checkbox>
                    </div>
                  </div>

                  <div class="col-sm-12 mt-3">
                    <h5 class="card-title">Mechanical Components</h5>
                    <div class="form-group-sm" v-for="(component, index) in mechanicComponents" :key="index">
                      <b-form-checkbox v-model="mechanicComponents[index].value" switch class="mb-1">
                        <label>{{component.name}}</label>
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>

                <div class="col-sm-2">
                  <h5 class="card-title">Accesorios</h5>
                  <div class="form-group-sm" v-for="(accesory, index) in accesories" :key="index">
                    <b-form-checkbox v-model="accesories[index].value" switch class="mb-1">
                      <label>{{accesory.name}}</label>
                    </b-form-checkbox>
                  </div>
                </div>

                <div class="col-sm-2">
                  <h5 class="card-title">Inventario</h5>
                  <div class="form-group-sm" v-for="(item, index) in inventory" :key="index">
                    <b-form-checkbox v-model="inventory[index].value" switch class="mb-1">
                      <label>{{item.name}}</label>
                    </b-form-checkbox>
                  </div>
                </div>
                <!-- EXTERNAL, INTERNAL, ACCESORIES, INVENTORY PILLS -->

                <!-- CAR MAP, GAUGE AND RANGE SLIDER -->
                <div class="col-sm-4">
                  <!--<img src="@/assets/images/mapa.png" height="100"/>-->
                  <vueSignature
                      class="mb-2"
                      ref="signature3"
                      :sigOption="option2"
                      :h="'300px'"
                      :disabled="disabled"
                      :defaultUrl="dataUrl"
                      style="width:100%; text-align: center; border: solid 1px;"
                    ></vueSignature>
                    
                    <button type="button" class="btn btn-sm btn-warning text-right" @click="clear('signature3')">Clear</button>
                    <!--
                    &nbsp;
                    <button type="button" class="btn btn-sm btn-warning text-right" @click="undo('signature3')">Undo</button>
                    -->
                  
                  <template>
                    <vue-gauge class="mt-5" :key="keyGaugeRender" :refid="'type-unique-id'" :options="gaugeOptions"></vue-gauge>
                  </template>

                  <div class="mt-1">
                    <vue-slide-bar v-model="num_gasoline" @dragEnd="updateGauge"/>
                  </div>
                </div>
                <!-- CAR MAP, GAUGE AND RANGE SLIDER -->
              </div>

              <!-- OBSERVATIONS -->
              <div class="row mt-2">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="de_observations">Observations</label>
                    <textarea name="textarea" v-model="form.de_observations" rows="5" class="form-control"></textarea>
                  </div>
                </div>
              </div>
              <!-- OBSERVATIONS -->

              <!-- RECEPTION SIGNATURES -->
              <div v-if="$route.params.id || user.user.id_role == 3" class="row mt-2">
                <div class="col-sm-6 text-center">
                      <div class="col-sm-12 text-center" style="border-bottom:solid 1px;">
                        <vueSignature
                          class="mb-2"
                          ref="signature4"
                          :sigOption="option"
                          :h="'150px'"
                          :disabled="disabled"
                          style="width:100%; text-align: center; border: solid 1px;"
                        ></vueSignature>
                      </div>
                      <p>Firma del Asesor de Servicio<br>&nbsp;</p>
                      <button type="button" class="btn btn-sm btn-warning" @click="clear('signature4')">Borrar Firma</button>
                    </div>

                    <div class="col-sm-6 text-center">
                      <div class="col-sm-12 text-center" style="border-bottom:solid 1px;">
                        <vueSignature
                          class="mb-2"
                          ref="signature5"
                          :sigOption="option"
                          :h="'150px'"
                          :disabled="disabled"
                          style="width:100%; text-align: center; border: solid 1px;"
                        ></vueSignature>
                      </div>
                      <p>Fecha y Firma del Consumidor<br>Aceptando Recepción del vehículo</p>
                      <button type="button" class="btn btn-sm btn-warning" @click="clear('signature5')">Borrar Firma</button>
                    </div>
              </div>
              <!-- RECEPTION SIGNATURES -->

              <!-- LABORS, COSTS, GUARANTEE -->
              <div class="row mt-5">
                <!-- SELECTABLE LABORS TABLE -->
                <div class="col-sm-6">
                  <h5 class="card-title">Refacciones</h5>
                  
                  <div class="form-group">
                    <b-form-group
                      label="Filter"
                      label-for="filter-input"
                      label-cols-sm="3"
                      label-align-sm="left"
                      label-size="sm"
                      class="mb-0"
                    >
                      <b-input-group size="sm">
                        <b-form-input
                          id="filter-input"
                          v-model="filter"
                          type="search"
                          placeholder="Type to Search"
                        ></b-form-input>

                        <b-input-group-append>
                          <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                        </b-input-group-append>

                        <b-input-group-append>
                          <button type="button" class="btn btn-sm btn-success text-right" v-b-modal.modal-labor><i class="fas fas fa-plus"></i> Add a labor</button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </div>
                  <div class="col-sm-12 text-right">
                    <b-badge pill variant="success" class="ml-1">Refacciones</b-badge>
                    <b-badge pill variant="info" class="ml-1">Mano de Obra</b-badge>
                    <b-badge pill variant="danger" class="ml-1">Servicios Externos</b-badge>
                  </div>
                  <b-modal no-close-on-backdrop
                    id="modal-labor"
                    title="New labor"
                    title-class="font-18"
                    size="lg"
                    hide-footer
                  >
                    <Labor @success="closeModal('modal-labor')"></Labor>
                  </b-modal>

                  <b-table
                    sticky-header
                    small
                    :items="labors"
                    :fields="laborsFields"
                    responsive="sm"
                    selectable
                    ref="selectableTable"
                    @row-clicked="rowClicked"
                    style="max-height: 450px !important;"
                    @filtered="onFiltered"
                    :filter="filter"
                    :tbody-tr-class="tbodyRowClass"
                    fixed
                  >
                    <!-- Example scoped slot for select state illustrative purposes -->
                    <template #cell(quantity)="data">
                        <div class="form-group">
                          <input
                            type="number"
                            class="form-control-sm"
                            name=""
                            id=""
                            min="0"
                            step="1"
                            oninput="this.value = Math.round(this.value);"
                            :value="data.value"
                            size="5"
                            @change="setShopPrice($event, data.index, data)"
                            style="width:100%;"
                            :disabled="$route.params.id  &&  user.user.id_role > 2  &&  user.user.id_role < 12"
                          >
                        </div>
                    </template>
                    <template #cell(shop_price)="data">
                        <div class="form-group">
                          <input
                            type="number"
                            class="form-control-sm"
                            name=""
                            id=""
                            :value="data.value"
                            size="5"
                            @change="setShopPrice($event, data.index, data)"
                            style="width:100%;"
                            :disabled="$route.params.id  &&  user.user.id_role > 2  &&  user.user.id_role < 12"
                          >
                        </div>
                    </template>
                    <template #cell(total)="data">
                        <div class="form-group">
                        {{(data.item.shop_price > 0) ? (parseFloat(data.item.shop_price) * parseFloat(data.item.quantity)).toFixed(2) : (parseFloat(data.item.suggested_price) * parseFloat(data.item.quantity)).toFixed(2)}}
                      </div>
                    </template>

                  </b-table>
                </div>
                <!-- SELECTABLE LABORS TABLE -->

                <!-- MAN WORK, SPARE PARTS, SUB, TAXTES, TOTAL, PRE-PAID, PENDING, EXCHANGE -->
                <div class="col-sm-4">
                  <h5 class="card-title">Importes</h5>
                  <div class="form-row form-group">
                    <label class="col-sm-4 col-form-label" for="num_externalService">Servicios Externos</label>
                    <div class="col">
                      <input
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': submitted && $v.form.num_externalService.$error }"
                        name="num_externalService"
                        id="num_externalService"
                        v-model="form.num_externalService"
                        value=""
                        readonly
                      >
                    </div>
                    <div v-if="submitted && $v.form.num_externalService.$error" class="invalid-feedback">
                      <span v-if="!$v.form.num_externalService.required">Servicios externos is required.</span>
                    </div>
                  </div>

                  <div class="form-row form-group">
                    <label class="col-sm-4 col-form-label" for="num_workForce">Mano de obra</label>
                    <div class="col">
                      <input
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': submitted && $v.form.num_workForce.$error }"
                        name="num_workForce"
                        id="num_workForce"
                        v-model="form.num_workForce"
                        value=""
                        readonly
                      >
                    </div>
                    <div v-if="submitted && $v.form.num_workForce.$error" class="invalid-feedback">
                      <span v-if="!$v.form.num_workForce.required">Mano de Obra is required.</span>
                    </div>
                  </div>

                  <div class="form-row form-group">
                    <label class="col-sm-4 col-form-label" for="num_spareParts">Refacciones</label>
                    <div class="col">
                      <input
                        type="text"
                        class="form-control"
                        name="num_spareParts"
                        id="num_spareParts"
                        v-model="form.num_spareParts"
                        value=""
                        readonly
                      >
                    </div>
                  </div>

                  <div class="form-row form-group">
                    <label class="col-sm-4 col-form-label" for="num_subTotal">Subtotal</label>
                    <div class="col">
                      <input
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': submitted && $v.form.num_subTotal.$error }"
                        name="num_subTotal"
                        id="num_subTotal"
                        v-model="num_subTotal"
                        value=""
                        readonly
                      >
                    </div>
                    <div v-if="submitted && $v.form.num_subTotal.$error" class="invalid-feedback">
                      <span v-if="!$v.form.num_subTotal.required">Subtotal is required.</span>
                    </div>
                  </div>

                  <div class="form-row form-group">
                    <label class="col-sm-4 col-form-label" for="num_taxes">IVA</label>
                    <div class="col">
                      <input
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': submitted && $v.form.num_taxes.$error }"
                        name="num_taxes"
                        id="num_taxes"
                        v-model="num_taxes"
                        value=""
                        readonly
                      >
                    </div>
                    <div v-if="submitted && $v.form.num_taxes.$error" class="invalid-feedback">
                      <span v-if="!$v.form.num_taxes.required">IVA is required.</span>
                    </div>
                  </div>

                  <div class="form-row form-group">
                    <label class="col-sm-4 col-form-label" for="num_totalPrice">Precio total</label>
                    <div class="col">
                      <input
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': submitted && $v.form.num_totalPrice.$error }"
                        name="num_totalPrice"
                        id="num_totalPrice"
                        v-model="num_totalPrice"
                        readonly
                      >
                    </div>
                    <div v-if="submitted && $v.form.num_totalPrice.$error" class="invalid-feedback">
                      <span v-if="!$v.form.num_totalPrice.required">Precio total is required.</span>
                    </div>
                  </div>

                  <div class="form-row form-group">
                    <label class="col-sm-4 col-form-label" for="num_advancePayment">Anticipo</label>
                    <div class="col">
                      <input
                        type="text"
                        class="form-control"
                        name="num_advancePayment"
                        id="num_advancePayment"
                        v-model="num_advancePayment"
                        value=""
                        readonly
                      >
                    </div>
                  </div>

                  <div class="form-row form-group">
                    <label class="col-sm-4 col-form-label" for="num_pendiente">Resta</label>
                    <div class="col">
                      <input
                        type="text"
                        class="form-control"
                        name="num_pendiente"
                        id="num_pendiente"
                        v-model="num_remains"
                        value=""
                        readonly
                      >
                    </div>
                  </div>

                  <div class="form-row form-group">
                    <label class="col-sm-4 col-form-label text" for="num_exchange">Tipo de Cambio</label>
                    <div class="col">
                      <input
                        type="number"
                        class="form-control"
                        name="num_exchange"
                        id="num_exchange"
                        v-model="form.num_exchange"
                        value=""
                        :readonly="$route.params.id  &&  user.user.id_role > 2  &&  user.user.id_role < 12"
                      >
                    </div>
                  </div>
                  <!-- MAN WORK, SPARE PARTS, SUB, TAXTES, TOTAL, PRE-PAID, PENDING, EXCHANGE -->
                </div>

                <div class="col-sm-2">
                  <h5 class="card-title">Garantía</h5>
                  <div class="form-row form-group">
                    <label class="col-sm-4 col-form-label" for="num_guaranteeDays">Días</label>
                    <div class="col">
                      <input
                        type="number"
                        class="form-control"
                        name="num_guaranteeDays"
                        id="num_guaranteeDays"
                        v-model="form.num_guaranteeDays"
                        value=""
                        :disabled="form.sn_guarantee"
                      >
                    </div>
                  </div>

                  <div class="form-row form-group">
                    <label class="col-sm-4 col-form-label" for="num_guaranteeMilesKM">KM / Millas</label>
                    <div class="col">
                      <input
                        type="number"
                        class="form-control"
                        name="num_guaranteeMilesKM"
                        id="num_guaranteeMilesKM"
                        v-model="form.num_guaranteeMilesKM"
                        value=""
                        :disabled="form.sn_guarantee"
                      >
                    </div>
                  </div>

                  <div class="form-group-sm mt-3 text-right">
                    <b-form-checkbox v-model="form.sn_guarantee" switch class="mb-1">
                      <label>Sin Garantía</label>
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
              <!-- LABORS, COSTS, GUARANTEE -->

              <!-- DIAGNOSTIC, DATA USE ACCEPTANCE, SIGNATURES -->
              <div class="row mt-5">
                <div class="col-sm-6">
                  <h5 class="card-title">Información de Diagnostico</h5>
                  <div class="form-group">
                    <label for="de_diagnostic">Diagnostico</label>
                    <textarea
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.de_diagnostic.$error }"
                      name="de_diagnostic"
                      id="de_diagnostic"
                      v-model="form.de_diagnostic"
                      value=""
                      rows="5"
                      :readonly="user.user.id_role == 3 || user.user.id_role == 5"
                    ></textarea>
                    <div v-if="submitted && $v.form.de_diagnostic.$error" class="invalid-feedback">
                      <span v-if="!$v.form.de_diagnostic.required">Diagnostico is required.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="dt_expeditionDate">Fecha de Expedición</label>
                    <br />
                    <date-picker
                      v-model="form.dt_expeditionDate"
                      :first-day-of-week="1"
                      lang="en"
                      :class="{ 'is-invalid': submitted &&  $v.form.dt_expeditionDate.$error }"
                      name="dt_expeditionDate"
                      id="dt_expeditionDate"
                      valueType="format"
                      format="YYYY-MM-DD"
                      :value="form.dt_expeditionDate"
                      :readonly="user.user.id_role == 3 || user.user.id_role == 5"
                    >
                    </date-picker>
                    <div v-if="submitted && $v.form.dt_expeditionDate.$error" class="invalid-feedback">
                      <span v-if="!$v.form.dt_expeditionDate.required">Fecha de Expedición is required.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="nm_diagnosticAuthor">Nombre y Firma de quien diagnostica.</label>
                    <input
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.nm_diagnosticAuthor.$error }"
                      name="nm_diagnosticAuthor"
                      id="nm_diagnosticAuthor"
                      v-model="form.nm_diagnosticAuthor"
                      value=""
                      :readonly="user.user.id_role == 3 || user.user.id_role == 5"
                    >
                    <div v-if="submitted && $v.form.nm_diagnosticAuthor.$error" class="invalid-feedback">
                      <span v-if="!$v.form.nm_diagnosticAuthor.required">Este campo is required.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="de_notes">Notas</label>
                    <textarea
                      class="form-control"
                      name="de_notes"
                      id="de_notes"
                      v-model="form.de_notes"
                      value=""
                      rows="5"
                    ></textarea>
                  </div>

                  <div class="form-group">
                    <label for="de_nextRevisionDateKM">Fecha/KM de próximo servicio</label>
                    <input
                      type="text"
                      class="form-control"
                      name="de_nextRevisionDateKM"
                      id="de_nextRevisionDateKM"
                      v-model="form.de_nextRevisionDateKM"
                      value=""
                    >
                    <span class="text-muted">Enter either a date or KM until next service.</span><br>
                    <span class="text-muted"><i>Date format, example: 2024-07-20.</i></span>&nbsp;
                    <span class="text-muted"><i>For KMs just enter the number.</i></span>
                  </div>
                </div>

                <div class="col-sm-6">
                  <h5 class="card-title">Descripción del servicio solicitado</h5>
                  
                  <div class="form-group-sm mt-3">
                    <b-form-checkbox v-model="form.sn_acceptSpareParts" switch class="mb-1">
                      <label>El consumidor autoriza que se utilicen partes o refacciones usadas o reconstruidas.</label>
                    </b-form-checkbox>
                  </div>

                  <div class="form-group-sm mt-3">
                    <b-form-checkbox v-model="form.sn_provideSpareParts" switch class="mb-1">
                      <label>Suministra partes, refacciones o materiales el cliente.</label>
                    </b-form-checkbox>
                  </div>

                  <p class="mt-5">En caso de que el presupuesto no sea aceptado, el consumidor pagará exclusivamente el costo de la revisión y diagnóstico, y el prestador de servicio se obliga a devolver el vehículo en las condiciones en las que le fue entregado, exceptuando las consecuencias inevitables del diagnóstico. El costo de la revisión será:</p>
                  <p class="mt-5">Autorización para la utilización de información con fines mercadotécnicos o publicitarios, el consumidor acepta SI( <input type="checkbox" ref="Datos1" :checked="usoDeDatos1" @change="usoDeDatos1=$event.target.checked;"> ) NO ( <input type="checkbox" ref="Datos2" :checked="usoDeDatos2" @change="usoDeDatos2=$event.target.checked;"> ) Acepta que el prestador del servicio ceda o transmita a terceros con fines mercadotécnicos o publicitarios, la información proporcionada por el motivo del presente contrato y SI ( <input type="checkbox" ref="Publicidad1" :checked="usoDePublicidad1" @change="usoDePublicidad1=$event.target.checked;"> ) NO ( <input type="checkbox" ref="Publicidad2" :checked="usoDePublicidad2" @change="usoDePublicidad2=$event.target.checked;"> ) acepta que el prestador del servicio le envíe publicidad sobre bienes y servicios.</p>

                  <div class="row mt-5">
                    <div class="col-sm-6 text-center">
                      <div class="col-sm-12 text-center" style="border-bottom:solid 1px;">
                        <vueSignature
                          class="mb-2"
                          ref="signature"
                          :sigOption="option"
                          :h="'150px'"
                          :disabled="user.user.id_role == 3 || user.user.id_role == 5"
                          style="width:100%; text-align: center; border: solid 1px;"
                        ></vueSignature>
                      </div>
                      <p>Firma del prestador de Servicio<br>&nbsp;</p>
                      <button type="button" class="btn btn-sm btn-warning" @click="clear('signature')">Borrar Firma</button>
                    </div>

                    <div class="col-sm-6 text-center">
                      <div class="col-sm-12 text-center" style="border-bottom:solid 1px;">
                        <vueSignature
                          class="mb-2"
                          ref="signature2"
                          :sigOption="option"
                          :h="'150px'"
                          :disabled="user.user.id_role == 3 || user.user.id_role == 5"
                          style="width:100%; text-align: center; border: solid 1px;"
                        ></vueSignature>
                      </div>
                      <p>Fecha y Firma del Consumidor<br>Aceptando y Autorizando Presupuesto</p>
                      <button type="button" class="btn btn-sm btn-warning" @click="clear('signature2')">Borrar Firma</button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- DIAGNOSTIC, DATA USE ACCEPTANCE, SIGNATURES -->

              <div class="row">
                <div class="col-lg-12 mt-5 pt-5">
                  <div class="form-group text-center">
                    <button type="button" class="btn btn-success" @click="checkHeadInvoice">SAVE WORKORDER</button>
                    <router-link to="/shop/workorders" class="ml-3">
                      <button class="btn btn-danger" type="reset">CANCEL </button>
                    </router-link>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div v-else class="card-body">
            <div class="row">
              <div class="col-sm-12 text-center">
                <error403></error403>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>



<style type="text/css">
  .is-invalid  .mx-input{
    border: solid 1px red !important;
  }
  .vue-gauge-item{
    margin-left: -40px !important;
  }
  
  /*.table-active, .table-active > th, .table-active > td {
    background-color: transparent !important;
    color: white;
  }*/
  input[type=checkbox] .is-invalid {
    border-color: #ff3d60;
  }

  .table-success, .table-success > th, .table-success > td{
    background-color: #1cbb8c !important;
    color: white;
  }

  .table-info, .table-info > th, .table-info > td{
    background-color: #4aa3ff !important;
    color: white;
  }

  .table-danger, .table-danger > th, .table-danger > td{
    background-color: #ff3d60 !important;
    color: white;
  }
</style>